.mat-accordion {
    &.collapsable {
        .mat-expansion-panel {
            border-radius: 0;
            box-shadow: none;
            background-color: transparent;
            overflow: hidden;
        }
        .mat-expansion-panel-header {
            padding: 0;
            pointer-events: none;
            height: auto;
            &.mat-expanded {
                height: auto;
            }
            .mat-expansion-panel-header-title {
                margin: 0;
                font-size: initial;
                color: initial;
            }
            .mat-expansion-indicator {
                display: none;
            }
            
        }
        .mat-expansion-panel-body {
            padding: 0;
        }
        &-xl {
            @media (min-width: 1200px) {
                .mat-expansion-panel-header {
                    pointer-events: initial;
                    padding: $size-8 $size-15;
                    border: $size-1 solid $gray-3;
                    border-radius: $size-8;
                    .mat-expansion-indicator {
                        @include inline-flex;
                    }
                }
                .mat-expansion-panel-body {
                    margin-top: $size-20;
                }
            }
        }
        &-lg {
            @media (max-width: 1199px) {
                .mat-expansion-panel-header {
                    pointer-events: initial;
                    padding: $size-8 $size-15;
                    border: $size-1 solid $gray-3;
                    border-radius: $size-8;
                    .mat-expansion-indicator {
                        @include inline-flex;
                    }
                }
                .mat-expansion-panel-body {
                    margin-top: $size-20;
                }
            }
        }
        &-md {
            @media (max-width: 991px) {
                .mat-expansion-panel-header {
                    pointer-events: initial;
                    padding: $size-8 $size-15;
                    border: $size-1 solid $gray-3;
                    border-radius: $size-8;
                    .mat-expansion-indicator {
                        @include inline-flex;
                    }
                }
                .mat-expansion-panel-body {
                    margin-top: $size-20;
                }
            }
        }
        &-sm {
            @media (max-width: 767px) {
                .mat-expansion-panel-header {
                    pointer-events: initial;
                    padding: $size-8 $size-15;
                    border: $size-1 solid $gray-3;
                    border-radius: $size-8;
                    .mat-expansion-indicator {
                        @include inline-flex;
                    }
                }
                .mat-expansion-panel-body {
                    margin-top: $size-20;
                }
            }
        }

        
        .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
            background-color: transparent;
        }
    }
}