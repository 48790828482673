//Padding
.p-10 {
  padding: $size-10 !important;
}
.pt-10 {
  padding-top: $size-10 !important;
}
.pr-10 {
  padding-right: $size-10 !important;
}
.pb-10 {
  padding-bottom: $size-10 !important;
}
.pl-10 {
  padding-left: $size-10 !important;
}
.px-10 {
  padding-left: $size-10 !important;
  padding-right: $size-10 !important;
}
.py-10 {
  padding-top: $size-10 !important;
  padding-bottom: $size-10 !important;
}

.pt-13{
padding-top: $size-13 !important;
}
.p-12 {
    padding: $size-12 !important;
}
.pt-12 {
    padding-top: $size-12 !important;
}
.pe-12 {
    padding-right: $size-12 !important;
}
.pb-12 {
    padding-bottom: $size-12 !important;
}
.ps-12 {
    padding-left: $size-12 !important;
}
.px-12 {
    padding-left: $size-12 !important;
    padding-right: $size-12 !important;
}
.py-12 {
    padding-top: $size-12 !important;
    padding-bottom: $size-12 !important;
}


.p-18 {
    padding: $size-18 !important;
}
.pt-18 {
    padding-top: $size-18 !important;
}
.pe-18 {
    padding-right: $size-18 !important;
}
.pb-18 {
    padding-bottom: $size-18 !important;
}
.ps-18 {
    padding-left: $size-18 !important;
}
.px-18 {
    padding-left: $size-18 !important;
    padding-right: $size-18 !important;
}
.py-18 {
    padding-top: $size-18 !important;
    padding-bottom: $size-18 !important;
}



.p-20 {
    padding: $p-20 !important;
}
.pt-20 {
    padding-top: $p-20 !important;
}
.pe-20 {
    padding-right: $p-20 !important;
}
.pb-20 {
    padding-bottom: $p-20 !important;
}
.pb-22{
    padding-bottom: $size-22 !important;
}
.ps-20 {
    padding-left: $p-20 !important;
}
.px-20 {
    padding-left: $p-20 !important;
    padding-right: $p-20 !important;
}
.py-20 {
    padding-top: $p-20 !important;
    padding-bottom: $p-20 !important;
}

.p-24 {
    padding: $size-24 !important;
    @media (max-width: 991px) {
      padding: $size-15 !important;
    }
}
.pt-24 {
    padding-top: $size-24 !important;
    @media (max-width: 991px) {
      padding-top: $size-15 !important;
    }
}
.pe-24 {
    padding-right: $size-24 !important;
    @media (max-width: 991px) {
      padding-right: $size-15 !important;
    }
}
.pb-24 {
    padding-bottom: $size-24 !important;
    @media (max-width: 991px) {
      padding-bottom: $size-15 !important;
    }
}
.ps-24 {
    padding-left: $size-24 !important;
    @media (max-width: 991px) {
      padding-left: $size-15 !important;
    }
}
.px-24 {
    padding-left: $size-24 !important;
    padding-right: $size-24 !important;
    @media (max-width: 991px) {
      padding-left: $size-15 !important;
      padding-right: $size-15 !important;
    }
}
.py-24 {
    padding-top: $size-24 !important;
    padding-bottom: $size-24 !important;
    @media (max-width: 991px) {
      padding-top: $size-15 !important;
      padding-bottom: $size-15 !important;
    }
}

.p-26 {
    padding: $size-26 !important;
}
.pt-26 {
    padding-top: $size-26 !important;
}
.pe-26 {
    padding-right: $size-26 !important;
}
.pb-26 {
    padding-bottom: $size-26 !important;
}
.ps-26 {
    padding-left: $size-26 !important;
}
.px-26 {
    padding-left: $size-26 !important;
    padding-right: $size-26 !important;
}
.py-26 {
    padding-top: $size-26 !important;
    padding-bottom: $size-26 !important;
}

.p-32 {
    padding: $p-32 !important;
}
.pt-32 {
    padding-top: $p-32 !important;
}
.pe-32 {
    padding-right: $p-32 !important;
}
.pb-32 {
    padding-bottom: $p-32 !important;
}
.ps-32 {
    padding-left: $p-32 !important;
}
.px-32 {
    padding-left: $p-32 !important;
    padding-right: $p-32 !important;
}
.py-32 {
    padding-top: $p-32 !important;
    padding-bottom: $p-32 !important;
}
.pb-30{
    padding-bottom: $size-30 !important;
}
.pb-35{
    padding-bottom: $size-35;
}

.pb-150 {
  padding-bottom: 150px !important;
}

@media (min-width: 1367px) {
    .px-xxl-4 {
        padding-left: $size-24 !important;
        padding-right: $size-24 !important;
    }
}

//Margin
.mt-10{
    margin-top: $m-10;
}
.m-20 {
    margin: $m-20 !important;
}
.mt-20 {
    margin-top: $m-20 !important;
}
.mr-20,
.me-20 {
    margin-right: $m-20 !important;
}
.mb-20 {
    margin-bottom: $m-20 !important;
}

.ml-20,
.ms-20 {
    margin-left: $m-20 !important;
}
.mx-20 {
    margin-left: $p-20 !important;
    margin-right: $p-20 !important;
}
.my-32 {
    margin-top: $p-20 !important;
    margin-bottom: $p-20 !important;
}

.m-32 {
    margin: $m-32 !important;
}
.mt-32 {
    margin-top: $m-32 !important;
}
.mr-32,
.me-32 {
    margin-right: $m-32 !important;
}
.mb-32 {
    margin-bottom: $m-32 !important;
}
.ml-32,
.ms-32 {
    margin-left: $m-32 !important;
}
.mx-32 {
    margin-left: $p-32 !important;
    margin-right: $p-32 !important;
}
.my-32 {
    margin-top: $p-32 !important;
    margin-bottom: $p-32 !important;
}

.gap-0 {
    gap: 0 !important;
}
.gap-2 {
    gap: $size-2 !important;
}
.gap-4 {
    gap: $size-4 !important;
}
.gap-6 {
    gap: $size-6 !important;
}
.gap-8 {
    gap: $size-8 !important;
}
.gap-10 {
    gap: $size-10 !important;
}
.gap-12 {
    gap: $size-12 !important;
}
.gap-14 {
    gap: $size-14 !important;
}
.gap-16 {
    gap: $size-16 !important;
}
.gap-20 {
    gap: $size-20 !important;
    @media (max-width: 991px) {
      gap: $size-15 !important;
    }
}
.gap-22{
    gap:$size-22 !important;
}
.gap-24 {
    gap: $size-24 !important;
}
.gap-32 {
    gap: $size-32 !important;
}

.column-gap-20 {
    column-gap: $size-20 !important;
    @media (max-width: 991px) {
      column-gap: $size-15 !important;
    }
}
.column-gap-24 {
    column-gap: $size-24 !important;
}
.column-gap-32 {
    column-gap: $size-32 !important;
}

.p-block-padding {
    padding: $size-20;
}
.px-block-padding {
    padding-left: $size-20;
    padding-right: $size-20;
}
