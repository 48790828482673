<div class="survey-dialog pb-20">
    <div class="countable-dialog survey-popup">
        <div mat-dialog-title class="dialog-header">
            @if(!showTqNote()){
            <h4 class="title">{{SurveyHeadings.Welcome}}</h4>
            }
        <button mat-button class="close" [mat-dialog-close]="false" (click)="close()">
            <i class="fa-regular fa-xmark clear-icon"></i>
        </button>
        </div>
        @if(!showTqNote()){
        <div mat-dialog-content class="dialog-body-content">
        <p class="fs-18 fw-medium custom-fontx">{{SurveyHeadings.SurveyRequestPara1}}</p>
        <p class="fs-18 fw-medium custom-font">{{SurveyHeadings.SurveyRequestPara2}}</p>
        <div class="survey-feed flexbox-row gap-8 justify-content-between">
            <ng-container *ngFor="let i of SurveyHeadings.SurveyQuestionIDs">
            <button class="text-link feed-button" (click)="onClickIcon(i)"
                [ngClass]="{'active': selectedRating === i, 'fade-out': selectedRating !== -1 && selectedRating !== i}">
                <img class="survey-icon" src="/assets/images/ui-uplift-icons/survey-assets/r-{{i}}.svg" alt="{{i}}">
                <span class="button-label">{{i}}</span>
                <span class="hr"></span>
            </button>
            </ng-container>
        </div>
        @if(showFeedback){
        <mat-form-field class="fixed-label w-100" floatLabel="always">
            <mat-label class="text-wrap fs-18 custom-font">{{selectedQuestion}}</mat-label>
            <textarea matInput placeholder="Share your feedback and suggestions..." cdkTextareaAutosize
            #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
            [(ngModel)]="feedbackText"></textarea>
        </mat-form-field>
        <div mat-dialog-actions class="dialog-footer button-group justify-content-end p-0">
            <button mat-button mat-stroked-button color="dark" class="button-medium w-auto flex-initial" [mat-dialog-close]="false"
            (click)="close()">Cancel</button>
            <button mat-button mat-flat-button color="primary" class="button-medium w-auto flex-initial" [mat-dialog-close]="true"
                (click)="submitFeedback('Done')" [disabled]="!feedbackText">Submit</button>
            </div>
        }
        </div>
        }
        @if(showTqNote()){
            <div mat-dialog-content class="dialog-body-content align-items-center pt-0">
                <span class="badge badge-success badge-circle icon-bordered-success">
                    <i class="fa-regular fa-circle-check text-success fs-4"></i>
                </span>
                <h3 class="title">{{SurveyHeadings.TqHeading}}</h3>
                <p class="fs-18 fw-regular">{{SurveyHeadings.TqParagraph}}</p>
            </div>
        }
    </div>
</div>
