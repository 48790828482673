// @import "../../../variables.scss";

// header .right-content {
//   float: right;
//   display: inline-block;
//   display: flex !important;
//   flex-flow: row nowrap;
//   justify-content: flex-start;
//   align-items: center;
//   height: 100%;
// }

// .dasboard-content-body header.sub-header .sub-header-btn {
//   margin-top: 0px !important;
// }

// .dasboard-container {
//   display: inline-block;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
// }

// .drawer-full {


//   & .side-nav {
//     width: 250px;
//   }

  // .dasboard-content-body {
  //   width: calc(100% - 250px);
  // }
// }

.blur-bg {
  &:after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 2;
    }
}


@import '../../../assets/scss/global/global';

@import '../../../assets/scss/layout/dashboard';

.side-nav {
  background-color: $primary-5;
}



.header-section{
  z-index: 1 !important;
}
  