

body {
  table.table-countable.add-team-members-table {
    th {
      white-space: inherit;
    }
  }
}

.add-team-members-table {
  .entity-type {
    @include fixed-width(200px);
  }
  .basis-for-calc {
    @include fixed-width(220px);
  }
  .current-period,
  .extra-polated-period,
  .benchmark-applied,
  .materiality-cy,
  .materiality-py,
  .amount {
    @include fixed-width(160px);
  }


  // Trail Balance table
  .mapno {
    @include fixed-width(110px);
  }


  // Create New Engagement
  .hourlyrate,
  .designation {
    @include fixed-width(130px);
  }
  .role {
    @include fixed-width(130px);
  }
  .budgetcost,
  .budgethours {
    @include fixed-width(154px);
  }
  .timeallocation {
      @include fixed-width(158px);
  }
  .teammembername {
    min-width: 120px;
    max-width: 120px;
  }
  .email {
    min-width: 140px;
    max-width: 140px;
  }
  .actions {
    @include fixed-width(90px);
  }
}


.bot-engagement-table-outer {
  @include flex-combinator(column, nowrap);
  align-self: flex-start;
  max-width: 100%;
  overflow: hidden;
}
.bot-engagement-table {
  .checbox-cell {
    @include fixed-width(40px);
  }
  .date-cell {
    @include fixed-width(116px);
    overflow: hidden;
  }
  .transaction-cell {
    @include fixed-width(170px);
    overflow: hidden;
  }
  .des-cell {
    min-width: 200px;
    overflow: hidden;
  }
  .ac-code-cell {
    @include fixed-width(115px);
    overflow: hidden;
  }
  .en-id-cell {
    @include fixed-width(190px);
    overflow: hidden;
  }
  .year-date-cell,
  .status-cell,
  .final-am-cell,
  .change-pr-cell {
    @include fixed-width(120px);
  }
  .acc-name-cell {
    @include fixed-width(200px);
  }
  .num-cell,
  .ls-code-cell {
    @include fixed-width(80px);
  }

  .acc-code-cell,
  .split-cell,
  .amount-cell,
  .balance-cell {
    @include fixed-width(100px);
  }
}

