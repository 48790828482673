.fade-animation {
  animation: fade-animation 4s infinite;
}

@keyframes fade-animation {
  0%  {opacity: 1;}
  20% {opacity: 0.6;}
  50% {opacity: 1;}
  65% {opacity: 0.6;}
  95% {opacity: 1;}
  100% {opacity: 1;}
}
