.layout-dashboard {
    background-color: $gray-50;
    display: block;
    height: 100%;

    .top-header {
        width: 100%;
        height: $nav-height;
        padding: 0 $size-15;
        background-color: $primary-5;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        @include flex-combinator(row, wrap, center, space-between);
        .brand {
            @include flex-combinator(row, wrap, center, center);
            .full-logo {
                display: none;
            }
            .small-logo {
                max-width: 33px;
            }
        }

        button {
            &.menu-collapse-btn {
                background-color: transparent;
                border: 0;
                padding: 0;
                transition: 0.3s;
                color: $white;
                min-width: auto;
                min-width: auto;
                mat-icon {
                    @include sequre($size-30);
                    font-size: $size-30;
                    line-height: $size-30;
                }
            }
        }

        @media (min-width: 992px) {
            display: none;
        }
    }

    .content-body {
        @include flex-combinator(row, wrap, stretch, flex-start);
        .left-sidebar {
            background-color: $primary-5;
            @include flex-combinator(column, nowrap);
            height: 100vh;

            .user-detail-main {
                margin-top: auto;
            }
            @include flex-combinator(column, nowrap);
            height: 100vh;

            .user-detail-main {
                margin-top: auto;
            }
        }


    }



    &.drawer-small {
        .left-sidebar {
            width: 0;
            overflow: hidden;
            position: relative;
            z-index: 4;
            @media (min-width: 1367px) {
                width: $collapsed-nav;
                overflow: visible;
            }
            @media (min-width: 992px) {
                width: 50px;
                overflow: visible;
            }
            .brand {
                @include flex-combinator(row, wrap, center, center);
                height: $nav-height;
            }
        }
    }
    &.drawer-full {
        .left-sidebar {
            position: fixed;
            left: 0;
            top: $nav-height;
            max-width: calc(100vw - 50px);
            width: 400px;
            height: calc(100vh - $nav-height);
            overflow-y: auto;
            z-index: 5;
            @media (min-width: 1367px) {
                width: $collapsed-nav;
            }
            @media (min-width: 992px) {
                width: 50px;
            }
            .brand {
                display: none;
            }
            @media (max-width: 667px) {
                z-index: 5;
            }
        }
    }
}

.left-sidebar {
    .left-nav {
        padding: 0;
        .mat-list-item,
        .mat-list-option {
            .mat-list-item-content {
                padding: 0;
            }
        }

    }
}

.nav-item {
  @include flex-combinator(row, wrap, center, flex-start);
  width: 100%;
  height: $nav-height;
  border-left: $size-4 solid transparent;
  position: relative;
  padding: 0 $size-20;
  background: transparent;
  border-width: 0 0 0 $size-4;
  @media (min-width: 992px) {
      padding: 0;
      border-right: $size-4 solid transparent;
      @include flex-combinator(row, wrap, center, center);
  }
  .nav-icon {
      img {
          opacity: 0.6;
      }
  }
  .nav-label {
      opacity: 0.6;
      color: $white;
      margin-left: $size-10;
      @media (min-width: 992px) {
          display: none;
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: $size-8;
          background-color: $white;
          color: $black;
          font-size: $size-12;
          font-weight: $semibold;
          padding: $size-8 $size-12;
          border: $size-1 solid $black;
          white-space: nowrap;
          z-index: 5;
          margin-left: 0;
          transform: translate($size-20, -50%);
          box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
      }
  }
  &:hover {
      border-left-color: $blue-astro-5;
      .nav-icon {
          img {
              opacity: 1;
          }
      }

      .nav-label {
          opacity: 1;
          @media (min-width: 992px) {
              @include flex-combinator(row, wrap, center, center);
              opacity: 1;
          }
      }
  }
  &.active {
      border-left-color: $blue-astro-5;
      background-color: $primary;
      .nav-label {
          opacity: 1;
          @media (min-width: 992px) {
            opacity: 0;
            display: none;
          }
      }
      .nav-icon {
          img {
              opacity: 1;
          }
      }

      &:hover {
        .nav-label {
            @media (min-width: 992px) {
              .nav-label {
                  opacity: 0;
                  display: none;
              }
                @include flex-combinator(row, wrap, center, center);
                opacity: 1;
                z-index: 999;
            }
        }
    }
  }
}

.user-detail-main {
    header {
        height: auto;
        background-color: Transparent;
        .left-content {
            display: none;
        }
    }
}

.dashboard-main {
    max-width: 100%;
    flex: 1;
    // height: calc(100vh - $nav-height);
    height: 100vh;
    overflow: auto;
    position: relative;
    @include flex-combinator(column, nowrap, initial, initial);
    // @media (min-width: 992px) {
    //     height: 100vh;
    // }
    @media (max-width:991px) {
      padding-top: $nav-height;
    }
}
.page-title-block {
    background-color: $gray-50;
    z-index: 1;
    padding: 15px;
    @include flex-combinator(row, wrap, center, space-between);
    gap: $size-16;
    @media (min-width: 991.99px) {
        padding: $size-20;
        position: sticky;
        top: 0;
        z-index: 2;
    }
    @media (min-width: 1366px) {
        padding: $size-20 $size-20;
    }
    @media (min-width: 1530px) {
        padding: $size-24 $size-24;
    }

    .title-left,
    .title-block-left {
        @include flex-combinator(column, wrap, flex-start, flex-start);
        column-gap: $size-10;
        width: auto;
        @media (min-width: 992px) {
            max-width: calc(60% - $size-20);
        }
    }

    .title-right,
    .title-block-right {
        @include flex-combinator(row, wrap, center, end);
        gap: $size-10;
        width: auto;
        @media (min-width: 992px) {
            max-width: 40%;
        }
    }

    .title {
        margin-bottom: 0;
    }
    .sub-title {
        margin: 0;
        color: $gray-6;
        font-weight: $medium;
    }
    &.fixed-header {
        position: absolute;
        top: $nav-height;
        width: 100%;
        background-color: $white;
        border-bottom: $size-1 solid $gray-3;
        @media (min-width: 992px) {
            top: 0;
        }
    }
}
.content-block-main {
    @include flex-combinator(column, nowrap);
    height: 100%;
    width: 100%;
    padding: 0 15px 15px;
    overflow: auto;
    @media (min-width: 1366px) {
        padding: 0 $size-20 $size-20;
    }
    @media (min-width: 1530px) {
        padding: 0 $size-24 $size-24;
    }
    &.fixed-header-content-block {
        padding-top: calc(91px + $nav-height);
        @media (min-width: 992px) {
            padding-top: 91px;
        }
    }
    @media (max-width: 991px) {
      //height: calc(100vh - $nav-height);
      height: calc(100vh - 200px);
        //height: 100%;
        //padding-bottom: 65px;
        padding-bottom:$nav-height;
    }
}

.right-sidebar {
    height: 100vh;
    @include flex-combinator(column, nowrap);

    .right-panel {
        height: 100vh;
        max-height: 100vh;
        // width: 350px;
    }
}
.repository-panel-show {
  .right-panel-outer {
    @media (max-width: 1199px) {
      position: fixed;
      z-index: 4;
      right: 0;
      top: 0;
      height: 100vh;
      background: $white;
    }
    @media (max-width: 991px) {
      top: $nav-height;
      height: auto;
    }
  }


  &.right-side-panel-hide {
    .right-panel-outer {
      background: transparent;

      .v-tabs {
        @media (max-width: 1199px) {
          display: none;
        }
      }
    }
    .right-sidebar,
    .right-panel {
        width: 50px;
        height: 100%;
    }
  }

  &.right-side-panel-show {
    .right-panel-outer {
      height: calc(100vh - $nav-height);
    }
      .right-sidebar,
      .right-panel {
          width: 350px;
          height: 100%;
      }
  }
}





.page-wraper {
    @include flex-combinator(column, nowrap);
    height: 100%;
    width: 100%;
    @media (max-width: 991px) {
      overflow: auto !important;
    }
}

.user-menu-main {
    width: 200px;
    margin-left: 50px;
    transform: translateY(50px);
    @media (max-width:991px) {
        transform: translateY(8px);
    }
}


.page-title-block.workbook-title-block {
  @media (min-width: 992px) {
    .title-block-left,
    .title-block-middle,
    .title-block-right {
      flex: 1;
    }
  }
}


@media (max-width:667px) {
    .dashboard-main{
            overflow-y: hidden;
            height: 100%;
    }
    .content-block-main{
        overflow-y: hidden;
        height: 100%;
        padding-bottom: 5px;
    }
}

.dashboard-main {
  &.clients-main,
  &.team-main {
    @media (max-width: 991px) {
      .page-title-block {
        position: fixed;
        top: 50px;
        background: #F8F8FA;
        width: 100%;
        z-index: 3;
      }
      .content-block-main {
        padding-top: 85px;
      }
    }
  }
}
