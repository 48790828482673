@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin inline-flex {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
}

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    -webkit-flex: $values;
    -ms-flex: $values;
    flex: $values;
}

@mixin flex-direction($direction) {
    -webkit-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
    -webkit-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

@mixin flex-flow($flow) {
    -webkit-flex-flow: $flow;
    -moz-flex-flow: $flow;
    -ms-flex-flow: $flow;
    flex-flow: $flow;
}

@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

@mixin flex-grow($grow) {
    -webkit-flex-grow: $grow;
    -moz-flex-grow: $grow;
    -ms-flex-grow: $grow;
    flex-grow: $grow;
}

@mixin flex-shrink($shrink) {
    -webkit-flex-shrink: $shrink;
    -moz-flex-shrink: $shrink;
    -ms-flex-shrink: $shrink;
    flex-shrink: $shrink;
}

@mixin flex-basis($width) {
    -webkit-flex-basis: $width;
    -moz-flex-basis: $width;
    -ms-flex-basis: $width;
    flex-basis: $width;
}

@mixin justify-content($justify) {
    -webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
    justify-content: $justify;
    -ms-flex-pack: $justify;
}

@mixin align-content($align) {
    -webkit-align-content: $align;
    -moz-align-content: $align;
    -ms-align-content: $align;
    align-content: $align;
}

@mixin align-items($align) {
    -webkit-align-items: $align;
    -moz-align-items: $align;
    -ms-align-items: $align;
    align-items: $align;
}

@mixin align-self($align) {
    -webkit-align-self: $align;
    -moz-align-self: $align;
    -ms-align-self: $align;
    align-self: $align;
}

@mixin flex-combinator($direction, $wrap, $align: initial, $justify: initial) {
    @include flexbox;
    @include flex-direction($direction);
    @include flex-wrap($wrap);
    @include align-items($align);
    @include justify-content($justify);
}

@mixin sequre($value) {
    height: $value;
    width: $value;
}

@mixin circle($value) {
    width: $value !important;
    height: $value !important;
    border-radius: 50%;
}

@mixin rounded($value, $radius) {
    height: $value;
    width: $value;
    border-radius: $radius;
    overflow: hidden;
}

@mixin button-flat($bg, $color, $bg-hover, $color-hover, $shadow-solid) {
    background: $bg;
    color: $color;
    &:hover {
     background: $bg-hover;
     color: $color-hover;
    }
    &:focus,
    &:active {
        background: $bg;
        color: $color-hover;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $shadow-solid;
        &:hover {
            background: $bg-hover;
            color: $color-hover;
        }
    }
    &:disabled {
        background: $gray-3 !important;
        color: $gray-5;
        border: $size-1 solid $gray-3;
        opacity: 1;
        pointer-events: initial !important;
    }
}

@mixin button-stroked($color, $border-color, $bg-hover, $color-hover, $border-color-hover, $shadow-solid) {
    background: transparent;
    color: $color;
    border: $size-1 solid $border-color;
    &:hover {
     background: $bg-hover;
     color: $color-hover;
     border-color: $border-color-hover;
    }
    &:focus,
    &:active {
        background: transparent;
        color: $color-hover;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $shadow-solid;
        &:hover {
         background: $bg-hover;
         color: $color-hover;
         border-color: $border-color-hover;
        }
    }
    &:disabled {
        background: $gray-50;
        color: $gray-5;
        border-color: $gray-3;
        opacity: 0.4;
    }
}

@mixin fixed-width($width) {
    min-width: $width !important;
    width: $width !important;
    max-width: $width !important;
}


@mixin max-breakpoint($max-value) {
  @media (max-width: $max-value) {

  }
}
@mixin min-breakpoint($min-value) {
  @media (minwidth: $min-value) {

  }
}

