@import "../../../../variables.scss";

header {
  display: block;
  width: 100%;
  background-color: $primary-color !important;
  height: 52px;
  padding: 0 24px 0 12px;
  box-sizing: border-box;
  &.sticky {
    position: absolute !important;
    z-index: 990 !important;
  }

  & .left-content {
    float: left;
    display: flex;
    height: 100%;

    & .branding {
      display: inline-block;
      float: left;
      margin-right: 24px;
      font-size: 34px;
      line-height: 1;
      margin-top: 8px;
    }

    & .dashboard-right-logo {
      display: flex;
      align-self: center;

      img {
        width: 84% !important;
      }
    }

    .user-label {
      margin: 15px 0 0 0;
      font-size: 14px;
      color: $black-v1-color;
    }
  }

  & .right-content {
    float: right;
    display: inline-block;

   & .timetracking {
      height: 30px;
      width: 50px;
    }

    & .notifctn, & .user-role {
      margin-right: 40px;
    }

    & .user-role {
      width: auto;
      color: $white-color;

      h6 {
        margin: 0 0 0 0;
        font-weight: normal;
      }

      & .ico {
        & .setting-ico {
          &:focus {
            outline: none;
          }
        }
      }
    }

    & .profile-pic {
      cursor: pointer;
    }

    & .short-user-label {
      border: none;

      & .avatar-content {
        font-size: 12px !important;
        font-family: 'Roboto', sans-serif !important;
      }
    }

    & .profile-pic {
      border: 1px solid $gray-v2-color;
      background-color: $white-color;
    }

    & .short-user-label, & .profile-pic {
      text-transform: uppercase;
      font-size: 10px;
      margin: 0px 0px 0 0;
      width: 28px;
      height: 28px;
      overflow: hidden;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.picUpdated {
        padding: 0px;

        & > img {
          width: auto !important;
          height: 100% !important;
        }
      }
    }

    & .notifctn, .timetracking {
      position: relative;

      & button.mat-icon-button {
        width: 28px;
        height: 28px;
        line-height: 28px;
        color: $white-color;
        & .hour_glass {
          height: 25px;
          width: 24px;
          padding-top: 1px;
        }

        & .material-icons {
          font-size: 27px
        }
      }

      & .badge {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 0px;
        left: 16px;
        z-index: 99;
        background-color: red;
        font-size: 0px;
        border-radius: 50%;
        border: 2px solid $primary-color;
      }
    }
  }

  .notifications {
    background-color: transparent;
    border: none;
    color: $gray-v7-color;
    font-size: 20px;
    margin: 0px 16px 0 0;
    padding: 0;
    width: 20px;
    height: 20px;
  }
}
.mat-menu-panel.user-dropdown.mat-menu-after.mat-menu-above,
.mat-mdc-menu-panel.user-dropdown.mat-menu-after.mat-menu-above,
.mat-menu-panel.leadsheet-container.mat-menu-after.mat-menu-above,
.mat-mdc-menu-panel.leadsheet-container.mat-menu-after.mat-menu-above {
  margin-bottom: 6px;
  position: relative;
  overflow: visible;
}
.user-dropdown {
  width: 235px;
  margin: 9px -12px 0 0;
  position: relative;
  overflow: visible !important;

  & button.mat-menu-item {
    line-height: 36px !important;
    height: 42px !important;
    font-size: 14px;
    color: $color-link;
    padding: 0px 16px;

    &:hover {
      background-color: $ntr-menu-active;
    }

    & .mat-icon {
      margin-right: 16px !important;
      vertical-align: middle;
    }
  }

  & .accbtn {
    display: flex !important;
    margin: 4px 0px;
  }
  button{
    &.accbtn.mat-menu-item{
      line-height: 42px !important;
    }
  }

  & .ico-color {
    color: $action-icons-color;

    &:hover {
      color: $primary-color;
    }
  }

  & .billing {
    margin-right: 19px;
    margin-left: 5px;
  }

  & .myaccount {
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  & .contact-us {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    margin-left: 4px;
  }

  & .my-account {
    font-size: 14px;
    color: $black-v1-color;
    padding-left: 12px;
    font-weight: 500;
  }

  & .icon-dropdown {
    border-bottom: 1px solid $gray-v13-color;
    border-top: 1px solid $gray-v13-color;
    padding: 8px 0px 14px 0px;
  }
}

.video-menu {
  & button.mat-menu-item {
    line-height: 36px !important;
    height: 36px !important;

    &:hover {
      background-color: $hover-color;
    }
  }
  &.lower{
    margin-top: 219px;
    min-height: 75px !important;
   // transform: translateY(219px);
  }
}

.badge-count {
  &.notify-badge.mat-badge.mat-badge-medium .mat-badge-content {
    border-radius: 39%;
    height: 19px;
    line-height: 13px;
    right: -21px !important;
  }
}


.time-badge.mat-badge.mat-badge-medium .mat-badge-content {
  right: -5px !important;
  top: -2px !important;
}

  .badge-count1 {
    &.notify-badge.mat-badge.mat-badge-medium .mat-badge-content {
      right: -26px !important;
    }
  }

  .notify-badge.mat-badge.mat-badge-medium .mat-badge-content {
    width: auto;
    min-width: 20px;
    padding: 4px;
    height: auto;
    line-height: 14px;
    right: -13px;
    top: -7px;
    font-size: 12px;
    z-index: 9;
    border-radius: 48%;
  }

  .time-badge.mat-badge.mat-badge-medium .mat-badge-content {
    width: auto;
    min-width: 20px;
    padding: 4px;
    height: auto;
    line-height: 14px;
    right: 2px;
    top: 0px;
    font-size: 12px;
    z-index: 9;
    border-radius: 48%;
  }

  .dasboard-content-body {
    &.subHeader {
      height: calc(100vh - 52px);
      max-height: calc(100vh - 52px);
    }
  }

  .clear-bodySpace-top {
    margin-top: -24px;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .clear-bodySpace-top .mat-tab-group {
    height: calc(100vh - 128px);
  }

  .clear-bodySpace-top .mat-tab-group .mat-tab-body-wrapper {
    height: 100%;
    overflow: hidden;
  }

  .mat-menu-panel, .mat-mdc-menu-panel {
    &.notifications-container {
      width: 520px;
      max-width: 520px;
      min-width: 520px;
    }
    &.hourglass-menu{max-width: unset;}
  }

  .disable {
    pointer-events: none;
  }

  .unread-notification {
    position: fixed;
    z-index: 9999;
    display: flex;
    right: 24px;
    flex-flow: row nowrap;
    cursor: pointer;
    box-shadow: 0 3px 9px -3px #777;
    top: 55px;
    width: 350px;
    background-color: #fff;
    align-items: center;
    justify-content: flex-start;
    padding: 25px 22px;
    border-radius: 3px;

  &.overdue-pop, &.reminder-pop{
    top:130px;
  }
  &.overdue-reminder-pop{
    top:205px;
  }
  &:hover {
    background-color: #EBF5FF;
  }

    & .blue-color {
      color: $notified-you-color;
    }

    & .convo {
      color: $primary-secondary-color;
    }

    & .preview-hover-items {
      position: absolute;
      top: 10px;
      right: 10px;


      & .more-btn {
        & .pc-icon {
          font-size: 22px;
          color: $grey-v41-color;
        }

        &.mat-button {
          min-width: 0;
          // height: 14px;
          line-height: 1;
          color: $black-color;
        }
      }
    }
  }

  .hourglass-menu {
    & .mat-menu-content {
      & .mat-menu-item {
        &:hover {
          background: $hover-color;
        }
      }
    }
  }

  .active-time {
    width: 560px;
    height: 297px;
    padding: 43px;


    &-header {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
    }

    &-body {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-bottom: 56px;
    }

    &-footer {
      display: flex;
      justify-content: center;

      & button {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .mat-menu-panel.hourglass-menu.mat-menu-before.mat-menu-below,
  .mat-mdc-menu-panel.hourglass-menu.mat-menu-before.mat-menu-below {
    margin-top: 12px;
    position: relative;
    overflow: visible;
    height: 552px !important;
    min-width: 1024px !important;
    max-width: 350px !important;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E0E0E0;
    opacity: 1;
    padding: 28px;

    &:before {
      position: absolute;
      top: -11px;
      right: -2px;
      content: '';
      width: 0;
      height: 0;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 16px solid #FFFFFF;
    }
  }
  .mandatedisable{
    pointer-events: none !important;
    opacity: 0.5 !important;
  }
//  menu align move left
.user-dropdown, .notifications-container, .hourglass-menu {
  margin-left: 50px !important;
}



// New UI responsiveness
@import "../../../../assets/scss/global/global";

.user-info-bar {
  @media (max-width: 991px) {
    @include flex-combinator(row, nowrap, center);
    display: flex !important;
    gap: $size-10;
    .mat-mdc-list-item {
      .mdc-list-item__content {
        overflow: visible;
      }
    }
    .user-info-item {
      width: auto;
      .nav-item {
        border-width: 0;
        padding: 0;
        .nav-label {
          display: none;
        }
      }
    }
  }
}
app-luca-launcher {
  display: flex;
  width: 100%;
  .luca-launch-btn {
    width: 36px;
    margin: 0 auto;
  }
}

@media (max-width:991px) {
.luca-responsive-icon{
  width: 100%;
  max-width: fit-content;
}
}

@media only screen and (max-width:667px) {
  .notification-count{
    min-width: 30px;
  }
}

.user-info-bar {
  .mat-badge-content {
    top: 0;
  }
}
