@import "../../../../variables.scss";

.notifications {
  padding: 0px;

  & .notification-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: $white-color;
    z-index: 99;
  }

  & .engagement-not {
    padding: 4px 16px 4px 23px;
    margin-bottom: 20px;

    & .select-all {
      align-self: center;
      padding-top: 8px;

      & img {
        margin-right: 8px;
      }

      &:hover {
        color: $primary-icon-color;

        & img {
          filter: invert(423%) saturate(2442%) hue-rotate( 215deg ) brightness(100%) contrast(46%);
        }
      }

      &:disabled {
        color: #A7A7A7;

        &.select-all:hover {
          color: #A7A7A7;

          & img {
            opacity: 0.4;
          }
        }
      }
    }

    .search-field {
      position: relative;
      top: 7px;
      margin-right: 13px;
      width: 220px !important;
      margin-top: 0px !important;

      & input {
        width: 100%;
        height: 36px !important;
        border: none;
        border: 1px solid #BAB9BD !important;
        background-color: transparent;
        border-radius: 4px !important;
        padding-left: 10px !important;
        padding-right: 0 !important;
        outline: none;
      }

      & .search-btn {
        position: absolute;
        right: 0;
        margin: 0px;

        & .search-icon {
          font-size: 24px;
          color: $gray-color;
        }
      }
    }

    & .map-head-dropdown {
      width: 208px;
      height: 43px;

      & .ng-dropdown-panel.ng-select-bottom {
        margin-top: 20px;
      }

      &.ng-select.ng-select-disabled .ng-select-container:after {
        border-bottom-color: rgba(0,0,0,0.42);
        background-image: none;
      }
    }
  }

  & ul {
    & li {
      border-bottom: 1px solid $gray-v2-color;
      //padding: 0 0px 0 16px;
      // margin-left: 23px;

      &:hover {
        background-color: $white-v6-color;
      }

      &:last-child {
        border-bottom: none;
      }

      & ul {
        padding-left: 0px;

        & li {
          margin-left: 0px !important;

          & .sub-section {
            padding: 10px 14px 10px 57px;
            border-top: 1px solid $gray-v2-color;

            & .preview-hover-items {

              & .notify-head {
                width: 265px;
                font-weight: 500;
              }

              & .notify-time {
                font-size: 12px;
                color: $darkgray-v1-color;
                padding-right: 12px;
              }

              & .more-btn {
                &.mat-button {
                  min-width: 0;
                  line-height: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  & .notification-item {
    padding: 16px 0px;
    padding-left: 16px;
    cursor: pointer;
    position: relative;

    & .team-member-short-name {
      margin-right: 0px;
      background-color: $sky-blue-color;

      & .avatar-shrt-name {
        & .avatar-container {
          width: 28px;
          height: 28px;

          & .avatar-content {
            text-align: center;
            border-radius: 100%;
            text-transform: uppercase;
            color: rgb(255, 255, 255);
            background-color: rgb(62, 149, 177);
            font: 9px / 28px Helvetica, Arial, sans-serif;
          }
        }
      }
    }

    & .icon {
      width: 24px;
      height: 24px;

      & .material-icons {
        font-size: 24px;
        color: $sky-blue-v5-color;
      }
    }

    & .notification-content {
      padding-left: 8px;
      width: 100%;
      max-width: calc(100% - 46px);

      & .notify {
        margin-bottom: 12px;

        &-time {
          font-size: 12px;
          color: $darkgray-v1-color;
          padding-right: 12px;
        }

        &-head {
          font-weight: 500;
          max-width: 250px;
        }
      }

      & .notify-content {
        width: 100%;

        & .blue-color {
          color: $notified-you-color;
        }

        & .bold-color {
          font-weight: 500;
        }
      }

      & .engagement-name {
        padding-bottom: 4px;
        font-size: 12px;
      }

      & .notification-heading {
        padding-bottom: 4px;
        font-weight: bold;
      }

      & .notification-desc {
        padding-bottom: 4px;
      }

      & .notification-date {
        font-size: 12px;
        color: $darkgray-v1-color;
        font-weight: 400;
        padding-top: 3px;
      }
    }

    & .preview-hover-items {
      & .more-btn {

        &.mat-button {
          min-width: 0;
          line-height: 1;
        }
      }
    }
  }



  & .noti-settings-container, & .client-settings-container {
    &-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $gray-v2-color;
      position: sticky;
      top: 0;
      background-color: $white-color;
      z-index: 99;
      padding: 0px 0px 0px 16px;

      & .float-left {
        /*padding-top: 12px;*/
        & h6 {
          font-weight: 700;
          margin: 0px;
        }
      }

      & .float-right {
        & .material-icons {
          color: $gray-v1-color;
        }
      }
    }

    &-body-section {

      .mat-expansion-panel {
        border-radius: 0px;

        &:last-of-type {
          box-shadow: none;
        }

        &-header {
          height: 40px !important;
          padding: 0 16px;

          & .mat-expansion-panel-header-title {
            & h6 {
              margin: 0px;
            }
          }
        }

        &-body {
          padding: 0 16px !important;
        }

        &.panel-border {
          border-bottom: 1px solid $gray-v11-color;
        }
      }

      & .engmnt-notifctn, & .other-notifctn {
        padding: 0px !important;

        & .notification-title {
        }

        & .engagement-details, & .other-details {
          margin-top: 24px;

          &-list {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            min-height: 28px;
            padding: 6px 0;
            border-bottom: 1px solid rgba(217, 217, 217, 0.35);

            & .float-left {
              width: 268px !important;
              max-width: 268px !important;
              min-width: 268px !important;
            }

            &-header {
              & p {
                margin: 0px;
                font-size: 14px;
                color: $secondary-color;
                font-weight: 400;

                &.italic-desc {
                  font-size: 12px;
                  font-weight: 100;
                  color: $gray-v4-color;
                  font-style: italic;
                }
              }
            }
          }
        }

        & .engagement-details, & .other-details {
          &.header-top {
            padding-top: 28px;
          }

          &-header {
            h6 {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  & .issues-comments-container {
    &-section {
      padding-top: 20px;
      align-items: flex-start;

      & .icons {
        width: 26px;
        height: 26px;
        line-height: 26px;

        & .icon {
          font-size: 26px;
        }
      }

      & .description {
        & .notify {
          font-size: 14px;
          color: #363636;

          & .notification {
            color: #0F5FA6;
            font-size: 14px;
          }
        }

        & .date, & .time {
          font-size: 12px;
          color: $darkgray-v1-color;
        }
      }

      & .more-vert {
        &-btn {
          width: 24px;
          height: 24px;
          line-height: 24px;
        }

        &-icn {
          color: #0F5FA6;
        }
      }
    }
  }

  & .notify-disable {
    margin: 70px 0px;

    & .notify-icon {
      font-size: 55px;
      color: $black-color;
      opacity: 0.3;
      margin-bottom: 50px;
    }

    & h2 {
      font-size: 26px;
      color: $gray-v4-color;
    }

    & h5 {
      font-size: 16px;
      color: $gray-v4-color;
    }
  }
}
.check-box {
  & button.mat-menu-item {
    &:hover {
      background: $hover-color;
    }
  }

  & .select-all {
    border-bottom: 1px solid #C6C6C6
  }
}
.clear-btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  & .clear-icon {
    font-size: 20px;
    line-height: 20px;
    color: $gray-v15-color;
    padding-right: 4px;
  }
}


@media (max-width:667px) {
 .mat-mdc-menu-panel.notifications-container {
    width: 100% !important;
    max-width: 300px !important;
    min-width: 350px !important;
    margin-left: 12px !important;
  }
  .notify-inner{
    flex-wrap: wrap !important;
  }
}