mat-radio-button,
.checkbox-group,
.radio-group,
.description-cell,
.card {
  page-break-inside: avoid;
}

.description-cell {
  min-height: 30px !important;
}

.print-font-13 {
  p, span, li, div, a, h6, .h6, h5, .h5 {
    font-size: 13px !important;
  }
  .fs-10 {
    font-size: 8px !important;
  }
}
.print-styling {
  .text-ellipse, .ellipse {
    white-space: initial !important;
  }
}
