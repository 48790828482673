//Variables
$black-color: #000000;
$black-v1-color: #141414;
$black-v2-color: #0C0C0C;
$black-v3-color: #212121;
$black-v4-color: #000;

$white-color: #FFFFFF;
$white-v1-color: #F9F9F9;
$white-v2-color: #f2f3f2;
$white-v3-color: #FAFAFA;
$white-v4-color: #fdfdfd;
$white-v5-color: #F9F9F9;
$white-v6-color: #F8F8F8;
$white-v7-color: #FCFCFC;
$white-v8-color: #fff;
$white-v9-color: #FFFDF4;
/*New Theme changes variable*/
$first-secondary-color:#337EBD;
$first-secondary-color-hover:#005EAC;
$login-body-color: #F6F6F6;
$primary-secondary-color: #1BA0F2; //
$action-icons-color: #A7C4CB; //
$table-header-color: #F0F0F0; //
$upload-bg-color: #F1F9FF;
$ntr-menu-active: #DDEDF8; //
$tbl-active-more-btn: #accfef;
$border-color: #E4EDEF;
$hover-color: #D8ECFE;
$primary-color: #0F60A6; //
$blue-v1-color: #0F60A6;
$blue-v2-color: #00508E;
$warning-icon-color: #fccf5d;
$primary-icon-color: #0F5FA6;
$notified-you-color: #1A9FF1;
/*New Theme changes variable End*/
$primary-v1-color: #fccf5d;
$primary-v2-color: #8E6600;
$primary-v3-color: #FFD500;
$primary-v4-color: #DEA922;
$primary-v5-color: #f1c148;
$primary-v6-color: #FBC02D;
$primary-v7-color: #FAB507;
$primary-v8-color: #FBBD23;
$primary-v9-color: #E89404;
$primary-v10-color: #ffc107;

$light-yellow: #FEF3D5;
$light-yellow-v1: #FDE19B;
$light-yellow-v2: #FFFAEF;
$dark-yellow: #DD8D22;

$due-date-color: #DFDFDF;
$info-color: #6B59AB;
$hyper-link-color: #0F60A6;
$comment-icon-color: #BAB9BD;
$secondary-color: #0F0F0F;
$secondary-v2-color: #424242;
$grey-v24-color: #f1f1f1;
$gray-v15-color: #9e9e9e;
$gray-v14-color: #EEEEEE;
$dark-gray-color:#6D6D6D;
$gray-v13-color: #707070;
$gray-v12-color: #717171;
$gray-v11-color: #E0E0E0;
$gray-v10-color: #f1f2f3;
$gray-v9-color: #9d9d9d;
$gray-v8-color: #ACABAB;
$gray-v7-color: #9b9b9b;
$gray-v6-color: #E6E6E6;
$gray-v5-color: #808080;
$gray-v4-color: #595959;
$grey-v41-color: #575757;
$gray-v3-color: #F2F2F2;
$gray-v26-color: #A6A6A6;
$gray-v2-color: #D9D9D9;
$gray-v1-color: #737373;
$gray-color: #9A9A9A;
$gray-v16-color: #BFBFBF;
$grey-v27-color: #ccc;
$dark-grey-color: #757575;
$gray-v17-color: #E2DDDD;
$gray-v18-color: #F3F3F3;
$gray-v19-color: #BCBCBC;
$gray-v22-color: #c1c1c1;
$gray-v20-color: #EDEDED;
$grey-v40-color: #A19E9E;
$gray-v25-color: #9E9E9E;
$gray-v38-color: #DFDFDF;
$gray-v39-color: #BAB9BD;
$grey-v23-color: #dcdcdc;
$gray-v28-color: #BDBDBD;
$gray-v29-color: #f2f2f2;
$darkgrey-color: #555555;
$darkgray-v1-color: #7A7A7A;
$darkgray-v2-color: #3F4041;
$gray-v30-color: #a9a9a6;
$gray-v31-color: #ECECEC;
$gray-v32-color: #ddd;
$gray-v33-color: #AAAAAA;
$gray-v34-color: #8E8E8E;
$gray-v35-color: #F4F4F4;
$gray-v36-color: #95989A;
$gray-v37-color: #8F8F8F;
$red-v5-color: #FFE4E4;
$red-v1-color: #f44336;
$red-color: #FF0000;
$red-v21-color: #F26666;
$light-pink-color: #EB7070;
$light-pink-v1-color: #F8A9A9;
$light-pink-v2-color: #ffc1c1;
$light-pink-v3-color: #F6A8AD;
$pink-v3-color: #9F6277;
$pink-color: #FFE8E9;
$pink-v4-color: #F26666;
$brown-coco-color: #BC8E60;
$archived-color: #E3E4E3;
$expansion-color: #DCE7EA;
$teal-color: #69DED6;
$teal-v1-color: #BBF1BA;
$teal-v2-color: #A4DEC5;

$green-color: #00874E;
$accept-green-color: #1A8700;

$sky-blue-color: #519FE6;
$sky-blue-v1-color: #B9D5F0;
$sky-blue-v2-color: #A3CAF0;
$sky-blue-v3-color: #B3E3FC;
$sky-blue-v4-color: #dee2e6;
$sky-blue-v5-color: #6CAEE0;
$sky-blue-v6-color: #9DB0E3;
$blue-1b-color:#337EBD;

$blue-v6-color: #007bff;
$blue-v7-color: #7BA2A2;

$darkblue-color: #2A35BA;
$violet-count: #6B59A8;
$violet-color: #8C5ECF;
$violet-v1-color: #C2A3F0;
$violet-v2-color: #AF62C1;
$violet-v3-color: #BA2AAC;
$violet-v4-color: #9E2ABA;


$beige-color: #F8D7B7;
$anchor-color: #224B74;
$change-status-color: #EC4461;
$change-status-green: #00874e;

$red-count-color: #D93540;
$light-red-color: #F7F7F7;
$red-border-color: #E90D0D;
$red-v2-color: #FF2801;

$green-v2-color: #4BBE44;
$green-v1-color: #62C199;
$green-v3-color: #30D126;
$green-v4-color: #008736;
$green-v5-color: #008265;
$green-v6-color: #2eaa50;
$green-v7-color: #74BF77;

$maroon-color: #BC4136;
$disabled-color: rgba(0, 0, 0, 0.38);

%full-width {
  display: inline-block;
  width: 100%;
}

%full-section {
  display: inline-block;
  width: 100%;
  height: auto;
}

%common-ico-setting {
  top: 12px;
  display: inline-block;
  margin-right: 8px;
}

%common-ico {
  width: 24px;
  height: 24px;
  position: relative;
}

%common-ico-before {
  display: inline-block;
  content: '';
  margin-right: 8px;
  width: 24px;
  height: 24px;
  background-size: 100% 100%;
}

%ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

%flex-box {
  display: flex;
  align-items: center;

  &-row {
    flex-flow: row nowrap;
  }

  &-column {
    flex-flow: column nowrap;
  }

  &-center {
    justify-content: center;
  }

  &-left {
    justify-content: flex-start;
  }

  &-right {
    justify-content: flex-end;
  }

  &-left-right {
    justify-content: space-between;
  }
}

// SETTINGS - VARIABLES

$color-link: #212121;
$color-separator: #767676;
$size-icon: 16px;
$space-separator: 8px;


// TOOLS - MIXINS

@mixin text-underline($color: currentColor) {
  background-image: linear-gradient($color, $color);
  background-size: auto 1px;
  background-repeat: repeat-x;
  background-position: 0 calc(50% + 1ex);
}

// TOOLS - FUNCTIONS

@function icon-svg-uri($icon-name, $color) {
  @if (type_of($color) == 'color') and (str-index(inspect($color), '#') == 1) {
    $encoded-color: '%23' + str-slice(inspect($color), 2);

    // MS Edge is turning single quotes to double quotes. (`url('data:` -> `url("data:`)
    $icon-set: ( arrow-left: "%3Cline x1='19' y1='12' x2='5' y2='12'%3E%3C/line%3E%3Cpolyline points='12 19 5 12 12 5'%3E%3C/polyline%3E", chevron-right: "%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E" );

    @if map-has-key($icon-set, $icon-name) {
      @return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$encoded-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round%5C'%3E#{map-get($icon-set, $icon-name)}%3C/svg%3E";
      /*@return "data:image/svg+xml,%3Csvg xmlns='./assets/images/bc_right_arrow.svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$encoded-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round%5C'%3E#{map-get($icon-set, $icon-name)}%3C/svg%3E";*/
    }
    @else {
      @error 'Invalid icon name: `#{$icon-name}`'
    }
  }
  @else {
    @error 'Invalid HEX value: `#{$color}`'
  }
}

////////////////////new color scheme//////////

$blue-1-color: #0F60A6;
$blue-1b-color:#337EBD;
$blue-2-color: #1BA0F2;
$blue-2b-color: #6BC3EB;
$blue-2c-color: #ECF8FF;

$black-color: #0F0F0F;
$white-color: #FFFFFF;
$purple-color: #6F53AD;
$burgundy-color: #990000;
$red-color: #E21212;
$pink-color: #F26666;
$light-pink-color: #FFF0F0;
$green-color: #339C7D;
$dark-green-color: #005224;
$light-green-color: #DDF0DF;
$tin-color: #A7C4CB;
$orange-color:#EB7A2A;
$light-orange-color: #F9D7BF;
$yellow-color: #E2E700;

$gray-color: #9A9A9A;
$gray-1-color: #212121;
$gray-2-color: #424242; 
$gray-3-color: #6D6D6D;

$light-gray-1-color: #BAB9BD;
$light-gray-2-color: #F0F0F0;

$active-links: #005EAC;

%blue-gradient {
  background-image: linear-gradient(to right, $blue-1-color , $blue-2-color);
}

%green-gradient {
  background-image: linear-gradient(to right, $blue-1-color , $green-color);
}


// topography
// display
%D {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  color: $black-color;
  &1{
    font-size: 84px;
    line-height: 108px;
  }
  &2{
    font-size: 72px;
    line-height: 92px;
  }
  &3{
    font-size: 60px;
    line-height: 76px;
  }
}

//heading
%H {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  color: $black-color;
  &1{
    font-size: 54px;
    line-height: 68px;
  }
  &2{
    font-size: 46px;
    line-height: 60px;
  }
  &3{
    font-size: 32px;
    line-height: 40px;
  }
  &4{
    font-size: 28px;
    line-height: 36px;
  }
  &5{
    font-size: 24px;
    line-height: 32px;
  }
  &6{
    font-size: 20px;
    line-height: 28px;
  }

}

//body
%B {

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  color: $black-color;

  &1{
    font-size: 20px;
    line-height: 28px;
  }
  &2{
    font-size: 16px;
    line-height: 24px;
  }
  &3{
    font-size: 14px;
    line-height: 20px;
  }

  &4 {
    font-size: 12px;
    line-height: 16px;
    font-family: 'Roboto';

    &.disabled {
      color: $light-gray-1-color;
    }
  }
  &5{
    font-size: 10px;
    line-height: 12px;
    font-family: 'Roboto';
  }
}
%bold{
  font-weight: 700;
}
//------ letter templates starts---//
%green-bg{
    padding: 2px 7px;
    background-color: #005224;
    border-radius: 5px;
    background: #DDF0DF;
    border: 1px solid #005224;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #005224;
}
%violet-cls{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  gap: 10px;
  width: 68px;
  height: 24px;
  background: #6F53AD !important;
  border-radius: 4px 4px 0px 0px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF !important;
}
%header-label {
  text-transform: uppercase;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  padding: 4px 8px;
  background: #6F53AD;
  border-radius: 4px 4px 0px 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  width: fit-content;
}
%elements{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 341px;
  height: 48px;
  background: #ECF8FF;
  mix-blend-mode: normal;
  border: 1px solid #337EBD;
  border-radius: 4px;
  padding: 0; 
  %hover{
    border: 2px solid #337EBD;
    width: 341px;
    height: 48px;
   }     
}
%headcls{
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  gap: 10px;
  width: auto;
  height: 200px !important;
  background: #F0F0F0;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
 text-align: center;
color: #6D6D6D;
}
%foot-cls{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
    gap: 10px;
    height: 70px;
    background: #F0F0F0;
    margin: 0 auto !important;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
%head-foot-text{
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #6D6D6D;
}
%tabs-right{
    min-width: 170px;
    max-width: 170px;
    width: 170px;
}
%dashed-border-hover{
  position: relative;
  width: auto;
  background: #F0F0F0;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  border: 1px dashed #85BECC;
  padding: 2px 25px 2px 2px;
  transition: 0.3s;
}
.mat-dialog-container{
  border: 1px solid #BAB9BD;
  box-shadow: 0px 4px 16px rgba(15, 15, 15, 0.2);
  border-radius: 4px;
}
%popup-new{
  box-sizing: border-box;
  width: 308px;
  %h1
  {
    font-family: 'Open Sans' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 24px;
    color: #0F0F0F;
    margin-top: 20px;
    margin-bottom: 20px;
  }
    %p{
      width: 316px;
      font-family: 'Open Sans' !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6D6D6D;
    }
    %btns-mid{
      display: block;
      margin: 0 auto;
      width: 96%;
      %btn-blue{
        padding: 20px !important;
        width: 120px;
        height: 36px;
        background: #337EBD; 
        border: 1px solid #337EBD;
        box-shadow: 0px 4px 16px rgba(15, 15, 15, 0.2);line-height: 0;
        margin-right: 15px;
        margin-left: 10px !important;
      }
      %btn-trans{
        padding: 20px !important;
        width: 120px;
        height: 36px;
        border: 1px solid #337EBD;
        background: #FFFFFF;
        box-shadow: 0px 4px 16px rgba(15, 15, 15, 0.2); line-height: 0;
      }
    }
}
%light-org{
      display: inline-block;
      padding: 2px 4px;
      gap: 10px;
      height: 24px;
      background: #F9D7BF;
      font-family: 'Open Sans' !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $black-color;
}
%btn-blue{
  padding: 20px;
  width: 120px;
  height: 36px;
  background: #337EBD; 
  box-shadow: 0px 4px 16px rgba(15, 15, 15, 0.2);line-height: 0;
}
%btn-trans{
  padding: 20px;
  width: 120px;
  height: 36px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(15, 15, 15, 0.2); line-height: 0;
}
%Allura {
  font-family: 'Allura' !important;
    font-weight: 400;
    font-size: 36px;
    line-height: 45px;
    color: #000000;
}

//------ letter templates ends---//