*{
    box-sizing: border-box !important;
}
.max-w-initial {
    max-width: initial !important;
}
.max-w-100 {
    max-width: 100% !important;
}
.max-w-200 {
    max-width: 200px !important;
}
.max-w-240 {
    max-width: 240px !important;
}
.max-w-250 {
    max-width: 250px !important;
}
.max-w-300 {
    max-width:300px !important;
}
.max-w-350 {
    max-width: 350px !important;
}
.max-w-400 {
    max-width: 400px !important;
}
.c {
    min-width: 350px !important;
    width: auto !important;
}
.w-44 {
    width: $size-44;
}
.w-90 {
    width: 90px;
}
.w-120 {
    width: 120px !important;
}
.w-150 {
    width: 150px !important;
}
.w-180 {
    width: 180px !important;
}
.w-200 {
    width: 200px !important;
}
.w-220 {
    width: 220px !important;
}
.w-300 {
    width: 300px !important;
    min-width: 300px !important;
}
.w-320{
    width: 320px !important;
}
.w-350 {
    width: 350px !important;
}
.w-400 {
    width: 400px !important;
    max-width: 100% !important;
}
.w-500 {
    width: 500px !important;
    max-width: 100% !important;
}
.w-600 {
    width: 600px !important;
    max-width: 100% !important;
}
.w-780 {
    width: 780px !important;
    max-width: 100% !important;
}
.w-924 {
    width: 924px !important;
    max-width: 100% !important;
}
.w-929{
    width: 929px !important;
}

.w-16 {
    width: $size-16 !important;
    min-width: $size-16 !important;
}

.min-h-auto {
    min-height: auto !important;
}
.min-w-150 {
  min-width: 150px !important;
}
.min-w-250 {
  min-width: 250px !important;
}

.width-70 {
  @include fixed-width(70px);
}
.width-80 {
  @include fixed-width(80px);
}
.width-90 {
  @include fixed-width(90px);
}
.width-100 {
  @include fixed-width(100px);
}
.width-110 {
  @include fixed-width(110px);
}
.width-120 {
  @include fixed-width(120px);
}
.width-130 {
  @include fixed-width(130px);
}
.width-140 {
  @include fixed-width(140px);
}
.width-150 {
  @include fixed-width(150px);
}
.width-160 {
  @include fixed-width(160px);
}
.width-170 {
  @include fixed-width(170px);
}
.width-180 {
  @include fixed-width(180px);
}
.width-190 {
  @include fixed-width(190px);
}
.width-200 {
  @include fixed-width(200px);
}
.width-210 {
  @include fixed-width(210px);
}
.width-220 {
  @include fixed-width(220px);
}
.width-230 {
  @include fixed-width(230px);
}
.width-240 {
  @include fixed-width(240px);
}
.width-250 {
  @include fixed-width(250px);
}
.width-300 {
  @include fixed-width(300px);
}
.width-320 {
  @include fixed-width(320px);
}
.width-430 {
  @include fixed-width(430px);
}
.width-450 {
  @include fixed-width(450px);
}
.width-470 {
  @include fixed-width(470px);
}
.h-initial {
  height: initial !important;
}
.h-200,
.height-200 {
    height: 200px;
}
.min-h-48 {
    min-height: $size-48;
}
.max-h-200 {
    max-height: 200px;
}
.h-500 {
    height: 500px;
}
.min-h-500 {
    min-height: 500px !important;
}
.max-h-500 {
    max-height: 500px !important;
}
.max-h-800 {
    max-height: 800px !important;
}

//Radius
.br-4 {
    border-radius: $radius-4;
}


body .border-end,
body td.border-end,
body th.border-end {
  border-right: $size-1 solid #dee2e6 !important;

}
