.card-shadow {
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06) !important;
}
.flex-0 {
  flex: 0 !important;
}

.flex-1 {
  flex: 1 !important;
}
.flex-initial {
  flex: initial !important;
}

.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden{
  overflow: hidden !important;
}
.vertical-middle,
.vertical-align-middle {
  vertical-align: middle !important;
}

.mat-calendar-body-cell {
&.mat-calendar-body-active {
    .mat-calendar-body-cell-content {
      &.mat-calendar-body-selected {
        background-color: $primary-darker;
        color: $white;
      }
    }
}
}

.bg-transparent {
  background: transparent !important;
}

.disable-container-list * {
pointer-events: none !important;
}


%flex {
@include flexbox;
}

body {
.engagements-table {
  .flex-table-body {
    .ft-cell {
      height: 50px;
      border-width: 0;
    }
    .mat-content {
      overflow: visible;
    }
  }
}
}
.disabled-mat-field{
  background-color: #F8F8FA;
  color: #778599;
  pointer-events: none;
}

mat-stepper.countable-stepper.chatbot-stepperr {
  .mat-horizontal-stepper-wrapper {
    .mat-horizontal-stepper-header-container {
      width: 700px;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}


.wrap-text-pre{
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
}
.top-left-tooltip-position{
  top: 0;
  transform: translate(-93%, -100%);
}

.ai-prep {
  position: relative;

  &:after {
      content: "";
      width: 100%;
      height: 100%;
      background: url(../../images/ai-prep-overlay.png) no-repeat;
      top: 0px;
      right: 0px;
      position: absolute;
      display: inline-block;
      background-size: 100% 100%;
      z-index: 9;
  }

}

.bottom-fix{
  position: absolute;
  left: 0;
  bottom: 0;
  width: auto;
  right: 12px;
  background-color: $white;
  z-index: 99;
  .addon-btns{
  padding-left: 30px;
  padding-right: 30px;
  }
  @media (max-width:667px) {
      width: 100% !important;
  }
}


@media (max-width: 1199px) {
  .ntr-panel-right > .vertical-tabs .toggle-btn {
      top: 50%;
      position: fixed;
      right: 0;
      left: initial;
      transform: translateY(-50%);
      background: #F1F1F3;
      border-radius: 0.5rem 0 0 0.5rem;
      width: $size-24;
      height: $size-30;
      @include flex-combinator(column, nowrap, center, center);
      padding: 0;
      border-radius: 0;
  }
}


@media (max-width:1199px) {
  .ntr-right-panel-desabled {
    .hide-right-panel {
      .v-tabs {
        display: none;
      }
    }
    .ntr-panel-right {
      margin-right: 0px;
      width: 0px;
      min-width: 0px;
    }
  }

  .ntr-panel-right {
    position: fixed;
    z-index: 4;
    right: 0;
    top: 0;
    height: 100%;
    background: $white;
  }

}

@media (max-width:991px) {
  .ntr-panel-right {
    top: 50px;
  }
}
@media (max-width:667px) {
 .header-section{
      z-index: 3 !important;
  }
  .common-head{
    padding-top: 32px !important;
  }
  .header-name{
    width: 100% !important;
    max-width: 130px !important;
  }
}
app-components {
  height: 100%;
}

app-ai-window{
  app-ai-preview{
    .chatbot-dialog{
      width: 100% !important;
      height: 100% !important;
      border-radius: 0px !important;
    }
  }
}


.context-tab-group {
  .mat-mdc-tab-list {
    @include flex-combinator(row, wrap);
  }
  .mat-mdc-tab-labels{
    flex: initial;
  }
  .mdc-tab-indicator--active{
    background-color: $primary-1;
  }
}
.context-select-field .ng-dropdown-panel .ng-dropdown-panel-items{
  max-height: 22vh !important;
}
