.mat-icon {
  width: auto !important;
  height: auto !important;
}
.icon-bordered-error {
    border: $size-8 solid $error-50 !important;
    &.bw-4 {
      border-width: $size-4 !important;
    }
}
.icon-bordered-primary {
    background-color: $secondary-hover !important;
    border: $size-8 solid $primary-1 !important;
    &.bw-4 {
      border-width: $size-4 !important;
    }
}
.icon-bordered-warning {
    background-color: $warning-100 !important;
    border: $size-8 solid $warning-1 !important;
    &.bw-4 {
      border-width: $size-4 !important;
    }
}
.icon-bordered-success {
    background-color: $success-1 !important;
    border: $size-8 solid $success-50 !important;
    &.bw-4 {
      border-width: $size-4 !important;
    }
}

%dot-property {
    @include inline-flex;
    background-color: $gray-3;
    color: $white;
    font-weight: $semibold;
    font-size: $size-12;
    @include flex-combinator(row, nowrap, center, center);
}

.dot,
.round,
.circle {
    @include inline-flex;
    @include circle($size-10);
    min-width: $size-10;
    background-color: $gray-3;

    &-12 {
        @extend %dot-property;
        @include circle($size-12);
        min-width: $size-12;
        font-size: $size-8;
    }

    &-14 {
        @extend %dot-property;
        @include circle($size-14);
        min-width: $size-14;
        font-size: $size-10;
    }

    &-16 {
        @extend %dot-property;
        @include circle($size-16);
        min-width: $size-16;
        font-size: $size-10;
    }

    &-18 {
        @extend %dot-property;
        @include circle($size-18);
        min-width: $size-18;
        font-size: $size-10;
    }

    &-20 {
        @extend %dot-property;
      @include circle($size-20);
      min-width: $size-20;
    }

    &-24 {
        @extend %dot-property;
        @include circle($size-24);
        min-width: $size-24;
    }

    &-28 {
        @extend %dot-property;
        @include circle($size-28);
        min-width: $size-28;
    }

    &-32 {
        @extend %dot-property;
        @include circle($size-32);
        min-width: $size-32;
    }

    &-34 {
        @extend %dot-property;
        @include circle($size-34);
        min-width: $size-34;
    }

    &-36 {
        @extend %dot-property;
        @include circle($size-36);
        min-width: $size-36;
    }

    &-40 {
        @extend %dot-property;
        @include circle($size-40);
        min-width: $size-40;
    }

    &-60 {
        @extend %dot-property;
        @include circle($size-60);
        min-width: $size-60;
    }

    &-warning {
        background-color: $warning;
    }
    &-primary {
        background-color: $primary;
    }
    &-secondary {
        background-color: $secondary;
    }
    &-success {
        background-color: $success;
    }
    &-tussock {
        background-color: $tussock;
    }
    &-gray {
        background-color: $gray-7;
    }
    &-gray-50 {
        background-color: $gray-50;
    }
    &-purple {
        background-color: $purple;
    }
    &-success {
        background-color: $success;
    }
    &-error {
        background-color: $error;
    }
}
