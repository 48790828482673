import { Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { STORAGE_KEYS, StorageHelper } from 'countable@helpers';
import { UserDetailModel } from 'countable@model';
import { BehaviorSubject, Subject } from 'rxjs';
import { WebSocketSubject } from 'rxjs/internal/observable/dom/WebSocketSubject';
import { webSocket } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { CONNECT_CHAT_TYPES } from '../model/connect/chatGroup.model';
import { MESSAGE_TYPE, WsMessageModel } from '../model/ws/ws-message.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService implements OnDestroy {

  public readonly SK: typeof STORAGE_KEYS = STORAGE_KEYS;
  public readonly messageSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public userDetail: UserDetailModel;
  private token: string | null = null;
  private readonly deviceId: string | null = null;
  private readonly unSubscribe$: Subject<void> = new Subject<void>();
  private subject: WebSocketSubject<WsMessageModel> = null;

  constructor(private authService: AuthService) {
    console.info('ws service constructor');
    // get the device key
    this.deviceId = StorageHelper.getItem(this.SK.WEB_SOCKET_UNIQUE_ID);
    if (!this.deviceId) {
      this.deviceId = uuidv4();
      StorageHelper.setItem(this.SK.WEB_SOCKET_UNIQUE_ID, this.deviceId);
    }
    this.authService.subjectToken.pipe(takeUntilDestroyed()).subscribe(token => {
      console.info('ws service: login detected');
      this.connect(token);
    });
    this.authService.subject.pipe(takeUntilDestroyed()).subscribe(status => {
      if (!status) {
        this.disconnect();
      }
    });

  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  private connect(token: string): void {
    this.subject = webSocket(environment.ws + '/ui?token=' + token);
    console.info('ws service: ws connection made: ', this.subject);
    const observableA = this.subject.multiplex(
      () => ({subscribe: MESSAGE_TYPE.WS_TYPE_ENG}), // When server gets this message, it will start sending messages
                                                     // for 'A'...
      () => ({unsubscribe: MESSAGE_TYPE.WS_TYPE_ENG}), // ...and when gets this one, it will stop.
      message => message.type === MESSAGE_TYPE.WS_TYPE_ENG // If the function returns `true` message is passed down the
                                                           // stream. Skipped if the function returns false.
    );

    const observableB = this.subject.multiplex( // And the same goes for 'B'.
      () => ({subscribe: MESSAGE_TYPE.WS_TYPE_CHAT}),
      () => ({unsubscribe: MESSAGE_TYPE.WS_TYPE_CHAT}),
      message => message.type === MESSAGE_TYPE.WS_TYPE_CHAT
    );

    const subA = observableA.subscribe(messageForA => console.log(messageForA));
    // At this moment WebSocket connection is established. Server gets '{"subscribe": "A"}' message and starts sending
    // messages for 'A', which we log here.

    const subB = observableB.subscribe(messageForB => console.log(messageForB));
    // Since we already have a connection, we just send '{"subscribe": "B"}' message to the server. It starts sending
    // messages for 'B', which we log here.
  }

  private disconnect(): void {
    // subB.unsubscribe();
    // Message '{"unsubscribe": "B"}' is sent to the server, which stops sending 'B' messages.

    // subA.unsubscribe();
    // Message '{"unsubscribe": "A"}' makes the server stop sending messages for 'A'. Since there is no more
    // subscribers to root Subject, socket connection closes.
  }
}
